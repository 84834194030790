@keyframes grow {
    0% { transform: scale(1); }
    100% { transform: scale(1.05); }
}

@keyframes fadein {
    from { opacity: 0.2; }
    to   { opacity: 1; }
}

div.galleryCoumnList {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    .column1, .column2, .column3, .column4 {
        width: 22.75%;
        margin-right: 42px;
        transition: ease-in-out 0.6s all;
    }
    .column4 {
        margin-right: 0;
    }
    li.galleryItem {  
        animation: fadein 1.5s;
        width: 100%;
        margin-bottom: 42px;
        box-sizing: border-box;
        text-align: center;
        color: white;
        perspective: 300px;
        transition: ease-in-out 0.6s all;
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
        img {
            width: 100%;
            height: auto;
        }
        div {
            background-size: cover;
            background-position: center;
            width: 100%;
        }
    }
}

@media (max-width: 1484px) {
    div.galleryCoumnList {
        .column1 {
            margin-left: 1.8%;
        }
        .column1, .column2, .column3, .column4 {
            width: 22.75%;
            margin-right: 1.8%;
        }
        li.galleryItem {  
            margin-bottom: 6.5%;
        }
    }
}

@media (max-width: 1024px) {
    div.galleryCoumnList {
        .column2 {
            margin-right: 1.8%;
        }
        .column1, .column2, .column3, .column4 {
            width: 45.50%;
            margin-left: 3%;
            margin-right: 0;
        }
        li.galleryItem {  
            margin-bottom: 6.5%;
        }
    }
}


@media (max-width: 767px) {
    div.galleryCoumnList li.galleryItem:hover {
        transform: none;
    }
}

@media (max-width: 450px) {
    div.galleryCoumnList {
        .column1, .column2, .column3, .column4 {
            width: 88%;
            margin: 0 6%;
        }
    }
}