@import '../Base/variables.scss';

.aboutContent {
    #blob1 {
        .imageBlock {
            height: 430px;
        }
        img {
            padding-left: 30px;
        }
    }
    #blob2 {
        .blob {
            height: 400px;
        }
        .imageBlock {
            height: 460px;
        }
        img {
            padding-right: 30px;
        }
    }
    
    #blob3 {
        margin-bottom: 90px;
    }
    #blob4 {
        margin: 20px 0;
        .blobText {
            flex-direction: column;
            justify-content: space-between;
            > img { 
                padding: 0 15px 0 40px;
            }
            p + p {
                margin: 0;
                padding: 0;
                img {
                    height: 395px;
                    width: auto;
                    display: block;
                }
            }
        }
        .imageBlock {
            height: 360px;
            height: 600px;
        }
    }
    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0;
        &.hasImage {
            .imageBlock {
                width: 48%;
                overflow: hidden;
                background-position: center center;
                background-size: cover;
            }
            .blobText {
                position: relative;
                width: 48%;
                display: flex;
                align-items: center;
                p {
                    padding: 20px 0;
                    img {
                        height: 300px;
                        width: 100%;
                    }
                }
                .blob {
                    position: absolute;
                    left: 0;
                    z-index: -1;
                    height: auto;
                    max-height: 410px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
        }

        &.blobText {
            position: relative;
            p {
                padding: 20px 0px;
            }
            .blob {
                position: absolute;
                top: -20px;
                left: 0;
                z-index: -1;
                height: 300px;
                padding: 0 20px;
                box-sizing: border-box;
                transform: translateZ(-3px) scale(1);
                width: 100%;
            }
        }

        p {
            font-family: $mainFont;
            text-align: left;
            font-size: 28px;
            line-height: 36px;
        }
    }
}

@media (max-width: 1484px) {
    .aboutContent {
        margin: 0 2%;
        > div {
            &.blobText .blob {
                height: auto;
                top: 0;
                bottom: 0;
                left: -22%;
                right: 0;
                width: 120%;
                margin: auto;
            }
            &.hasImage {
                .imageBlock {
                    width: 40%;
                }
                .blobText {
                    width: 56%;
                    
                }
            }
        }
        #blob2 {
            margin: 70px 0;
            .imageBlock {
                max-height: 320px;
            }
        }
        #blob3 {
            margin: 80px 0;
        }
        #blob4 {
            .blobText {
                > img {
                    max-height: 190px;
                }
                p + p img {
                    max-height: 395px;
                    max-width: 100%;
                    height: auto;
                    width: auto;
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .aboutContent {
        br {
            display: none;
        }
        margin: 0 4%;
        > div {
            flex-direction: column;
            margin-top: 40px;
            p {
                font-size: 22px;
                line-height: 32px;
            }
            div {
                margin-bottom: 40px;
            }
            &.blobText .blob {
                height: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
            &.hasImage {
                .imageBlock {
                    width: 100%;
                }
                .blobText {
                    width: 100%; 
                    margin: 40px 0;
                    .blob {
                        max-height: 270px;
                        max-width: 700px;
                        right: 0;
                        margin: auto;
                    }
                }
            }
        }
        #blob1 {
            flex-direction: column-reverse;  
            margin-bottom: 0;
            .blobText {
                margin-bottom: 80px;
                .blob {
                    max-height: 230px;
                }
            }
            .imageBlock {
                max-width: 600px;
                margin: 0 auto;
            }
        }
        #blob2 { 
            margin-bottom: 0;
            .blobText {
                margin-bottom: 90px;
            }
            .imageBlock {
                max-width: 600px;
                margin: 0 auto;
            }
        }
        #blob3 { 
            margin-bottom: 0;
            margin-top: 0;
            > img {
                display: none;
            }
        }
        #blob4 {
            margin-top: 0;
            .blobText {
                > img {
                    max-height: 140px;
                }
                p + p {
                    margin-top: 20px;
                }
            }
            .imageBlock {
                max-width: 550px;
                margin: 0 auto;
            }
        }
    }
}


@media (max-width: 560px) {
    .aboutContent {
        br {
            display: none;
        }
        margin: 0 4%;
        > div.hasImage .blobText .blob {
            max-height: 260px;
        }
        #blob1 {
            flex-direction: column-reverse;  
            margin: 0;
            .blobText {
                margin-bottom: 50px;
            }
            .imageBlock {
                max-height: 350px;
                margin-bottom: 0;
            }
        }
        #blob2 {
            margin-top: 0;
            .blobText {
                margin-bottom: 50px;
                margin-top: 50px;
            }
            .imageBlock {
                max-height: 320px;
            }
        }
        #blob3 {
            margin-top: 0;
            > img {
                display: none;
            }
        }
        #blob4 {
            margin-top: 0;
            .imageBlock {
                max-height: 450px;
                margin: 0;
            }
            .blobText {
                margin-top: 0;
                > img {
                    display: none;
                }
                p + p {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .aboutContent {
        br {
            display: none;
        }
        .blobText {
            margin: 0 !important;
            p {
                margin: 0;
            }
            .blob {
                display: none;
            }
        }
        #blob1 {
            margin-top: 0;
            .blobText p {
                padding-top: 0;
            }
            .imageBlock {
                max-height: 300px;
            }
        }
        #blob2 {
            .imageBlock {
                max-height: 260px;
            }
        }
        #blob4 {
            margin-top: 0;
        }
    }
}