@import './components/Base/variables.scss';

a {
  webkit-transition: background-color 150ms linear, color 150ms linear;
  -ms-transition: background-color 150ms linear, color 150ms linear;
  transition: background-color 150ms linear, color 150ms linear;
  svg {
    webkit-transition: color 150ms linear;
    -ms-transition: color 150ms linear;
    transition: color 150ms linear;
  }
}

svg {
  webkit-transition: color 150ms linear;
  -ms-transition: color 150ms linear;
  transition: color 150ms linear;
}

h1 {
  font-weight: 400;
}

h2 {
  font-weight: 300;
}

button {
  outline: none;
}

body {
  font-family: $mainFont;
  margin: 0;
  color: $text_colour;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1400px;
  @media (max-width: 1484px) {
    width: calc(100%);
  }
}

.ril__toolbar {
  background: none;
}

.ril__navButtonNext {
  background-color: $primaryColour;
  transition: all 0.6s;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
}

.ril__navButtonPrev {
  background-color: $primaryColour;
  transition: all 0.6s;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
}

.ril__toolbarItem {
  background-color: $primaryColour;
  transition: all 0.6s;
  padding: 0 5px;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
}

.ril__outer {
  background-color: rgba(255, 255, 255, 0.85);
}

.ril__errorContainer {
  color: $text_colour;
}

.ril__loadingCirclePoint::before {
  background-color: $primaryColour;
}

p.loading {
    text-align: center;
    padding: 20px 0;
    width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.heading {
  text-align: center;
  width: 100%;
}

.App {
  text-align: center;
}

.App-link {
  color: #09d3ac;
}

.popup-content {
  width: auto !important;
}

.popup-content img {
  max-height: calc(100vh - 160px);
  width: auto;
  display: block;
}