@import '../Base/variables.scss';

@keyframes fadein {
    from { opacity: 0.2; }
    to   { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 0.7; }
    to   { opacity: 0; }
  }

.formWrap {
    margin: 30px 0 120px 0;
    form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        input {
            width: 49%;
            margin-bottom: 2%;
            &:nth-child(even) {
                margin-left: 2%;
            }
        }
        label {
            display: none;
        }
        textarea {
            width: 100%;
            margin-bottom: 2%;
            height: 228px;
        }
        textarea, input{
            font-family: $mainFont;
            border: none;
            padding: 20px;
            font-size: 20px;
            box-sizing: border-box;
            background-color: #f4f4f4;
        }
        button {
            align-self: flex-end;
            border: none;
            background-color: $primaryColour;
            color: #ffffff;
            padding: 20px 50px;
            font-size: 20px;
            webkit-transition: background-color 150ms linear, color 150ms linear;
            -ms-transition: background-color 150ms linear, color 150ms linear;
            transition: background-color 150ms linear, color 150ms linear;
            &:hover {
                background-color: $secondaryColour;
                cursor: pointer;
            }
        }
    }
    .alertBox {
        background-color: $primaryColour;
        padding: 30px 0;
        margin: 60px 0 0;
        animation: fadein 1s;
        p {
            font-size: 20px;
            color: #ffffff;
        }
    }
    .successBox {
        background-color: #6df8b5;
        padding: 30px 0;
        margin: 60px 0 0;
        animation: fadein 1s;
        p {
            font-size: 20px;
            color: #ffffff;
        }
    }
    h2 {
        font-size: 30px;
        text-align: left;
    }
}

@media (max-width: 1484px) {
    .formWrap {
        margin-left: 2%;
        margin-right: 2%;
    }
}

@media (max-width: 767px) {
   .formWrap {
        margin-bottom: 60px;
        margin-left: 4%;
        margin-right: 4%;
       h2 {
           text-align: center;
       }
       .alertBox, .successBox {
           margin-top: 40px;
       }
       form {
            input {
                width: 100%;
                margin-bottom: 20px;
                &:nth-child(even) {
                    margin-left: 0;
                }
            }
            textarea {
                margin-bottom: 30px;
            }
            > div {
                width: 100%;
                > div {
                    margin: 0 auto;
                    width: 303px;
                }
            }
            button {
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}