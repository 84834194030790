@import './variables.scss';

footer {
    position: relative;
    overflow: hidden;
    padding: 60px 0 10px;
    min-height: 160px;
    .backgroundColour {
        background-color: $primaryColour;
        height: 200px;
        width: 100%;
        position: absolute;
        display: block;
        margin-top: 27px;
        content: '';
        z-index: -2;
    }
    > svg {
        position: absolute;
        width: 100%;
        max-width: 2000px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        path {
            fill: #F77F8D;
        }
    }
    .pageWidth {
        margin: 0 auto;
        width: 1400px;
        display: flex;
        flex: 1 1;
        padding-top: 50px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: stretch;
        align-content: flex-start;
        flex-wrap: wrap;
    }
    .footerContainer {
        // background-color: $primaryColour;
        > div {
            .mainLogo {
                display: block;
                width: 350px;
            }
            p {
                text-align: left;
                margin: 10px 0;
            }
            svg {
                color: #fdc1c8;
                margin-left: 30px;
                height: 42px;
                g path {
                    fill: #fdc1c8;
                    transition: fill 0.6s ease;
                    clip-path: none;
                }
                &:hover g path {
                    cursor: pointer;
                    fill: #ffffff;
                }
            }
            div {
                align-self: baseline;
            }
        }
        div + div {
            a {
                float: right;
            }
            p {
                clear: both;
                text-align: right;
            }
        }
    }
    p {
        color: #ffffff;
        span {
            font-family: sans-serif;
        }
    }
}


@media (min-width: 2000px) {
    footer .pageWidth {
        position: relative;
        &:after {
            content: '';
            background-color: #F77F8D;
            position: absolute;
            width: 100%;
            z-index: -1;
            top: -60px;
            height: 100px;
            left: 1700px;
        }
    }
}


@media (max-width: 1484px) {
    footer .pageWidth {
        width: 100%;
        padding: 0 3%;
        padding-top: 40px;
    }
}

@media (max-width: 991px) {
    footer {
        padding: 40px 0 10px;
    }
}

@media (max-width: 767px) {
    footer {
        padding: 0 0 10px;
        > svg {
            width: 130%;
            &:first-child {
                margin-left: 0;
            }
        }
        .backgroundColour {
            height: 350px;
            margin-top: 45px;
        }
        .footerContainer {
            width: 100%;
            div > div {
                width: 100%;
                padding: 55px 0 30px 0;
                .mainLogo {
                    margin: 0 auto;
                }
                p {
                    text-align: center;
                }
            }
        }
        .pageWidth {
            flex-direction: column;
            align-items: center;
            div + div {
                padding: 0;
                width: 100%;
                a {
                    float: none;
                    svg {
                        margin-bottom: 10px;
                    }
                }
                p {
                    text-align: center;
                }
            }
        }
    } 
}
@media (max-width: 479px) {
    footer {
        padding: 0 0 10px;
        .footerContainer {
            div > div {
                padding: 55px 0 30px 0;
            }
            div + div {
                padding: 0;
            }
        }
    }
}