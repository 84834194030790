@import './variables.scss';

@font-face {
    font-family: 'cookiesandmilk';
    src: url('../../fonts/cookies_milk/cookies&milk-Light.otf');
  }

  @font-face {
    font-family: 'newcicle';
    src: url('../../fonts/new_cicle/New Cicle Fina.ttf');
  }

  @font-face {
    font-family: 'newciclethick';
    src: url('../../fonts/new_cicle/New Cicle Gordita.ttf');
  }

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.navWrapper {
    width: 100%;
    .mobileButton {
        display: none;
    }
    .mainLogo {
        margin: 0 auto;
        padding: 28px 25px;
        display: block;
        img {
            width: 620px;
        }
    }
    nav {
        display: flex;
        justify-content: center;
        padding: 0 30px;
        font-family: $mainFont;
        a {
            text-decoration: none;
            color: #777777;
            padding: 20px 60px;
            font-family: $manuFont;
            display: block;
            font-size: 24px;
            text-transform: uppercase;
            svg {
                font-size: 26px;
            }
            &:hover, &.isActive {
                color: $hover_colour;
            }
            &:last-child:hover {
                svg {
                    animation-name: spin;
                    animation-duration: 5000ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear; 
                }
            }
        }
    }    
}

@media (max-width: 1280px) {
    .navWrapper {
        .mainLogo {
            margin: 0 auto;
            padding: 28px 25px;
            display: block;
            img {
                width: 520px;
            }
        }
        nav a {
            font-size: 20px;
        }
    }
}

@media (max-width: 767px) {
    .navWrapper {
        margin-bottom: 20px;
        .mainLogo {
            img {
                width: 420px;
            }
        }
        svg {
            display: block;
        }
        .mobileButton {
            display: block;
            margin-top: 10px;
            padding: 12px 0;
            box-shadow: 0px 0px 10px 10px #e8e8e8;
            svg {
                color: $text_colour;
                font-size: 32px;
                margin: 0 auto;
            }
        }
        .nav {
            padding: 0;
            width: 100%; 
            flex-direction: column;
            max-height: 0;
            transition: max-height 0.5s ease-out;
            height: auto;
            overflow: hidden;
            border: none;
            &.active {
                height: auto;   
                max-height: 570px;
                transition: max-height 0.5s ease-in;
            }
            a {
                background-color: $primaryColour;
                padding: 32px 13px;
                margin: 0 0 12px;
                color: #ffffff;
                border: none;
                &:last-child {
                    margin-bottom: 0;
                }
                &.mainLogo {
                    box-shadow: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding: 22px 0 28px;
                    background: none;
                    border: none;
                    svg {
                        width: 120px;
                        height: 60px;
                        margin: 0 auto;
                    }
                    &.isActive {
                        background: none;
                        border: none;
                    }
                    &:hover {
                        background: none;
                        border: none;
                    }
                }
                &.isActive {
                    background-color: lighten($color: $primaryColour, $amount: 8);
                    border: none;
                    color: #ffffff;
                }
                &:hover {
                    background-color: lighten($color: $primaryColour, $amount: 8);
                    color: #ffffff;
                    border: none;
                }
                &:after {
                    border: 0;
                }
            } 
        } 
    }
}

@media (max-width: 560px) {
    .navWrapper {
        .mainLogo {
            margin: 0 auto;
            padding: 28px 25px;
            display: block;
            img {
                width: 320px;
            }
        }
        nav a {
            font-size: 20px;
        }
    }
}