@import "../Base/variables.scss";

.holdingPage {
    padding: 30px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .secondaryContainer {
        .blobtext {
            position: relative;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            .blob {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                z-index: -1;
                height: auto;
                max-width: 730px;
                padding: 0 20px;
                box-sizing: border-box;
                width: 100%;
            }
        }
        h2 {
            margin-top: 110px;
            font-size: 72px;
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
        }
        p {
            font-size: 24px;
            margin: 0 0 40px;
            width: 100%;
            text-align: center;
        }
        ul.socials {
            margin: 120px auto 0;
            display: flex;
            justify-content: center;
            svg {
                height: 90px;
                margin: 0 46px;
                g path {
                    transition: fill 0.6s ease;
                    clip-path: none;
                }
            }
            li {
                a:hover {
                    svg g path {
                        fill: #a3a3a3;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    .holdingPage {
        .secondaryContainer {
            .blobtext {
                height: 32%;
                color: $text_colour;
                .blob {
                    display: none;
                }
                h2 {
                    font-size: 60px;
                    margin-top: 80px;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 20px;
                }
            }
            span {
                font-size: 26px;
                margin: 55px 0 30px;
            }
            ul.socials {
                margin: 60px auto 0;
                svg {
                    height: 60px;
                    margin: 0 29px;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .holdingPage {
        .secondaryContainer {
            span {
                font-size: 18px;
                margin: 55px 0 30px;
            }
            ul.socials {
                svg {
                    height: 45px;
                    margin: 0 17px;
                }
            }
        }
    }
}

@media ( max-height: 800px ) and (min-width: 900px) {
    .holdingPage {
        .secondaryContainer {
            span {
                font-size: 26px;
                margin: 50px 0 30px;
            }
            ul.socials {
                svg {
                    height: 60px;
                    margin: 0 29px;
                }
            }
        }
    }
}

@media ( max-height: 585px ) and (min-width: 900px) {
    .holdingPage {
        .secondaryContainer {
            span {
                font-size: 20px;
                margin: 50px 0 30px;
            }
            ul.socials {
                svg {
                    height: 40px;
                    margin: 0 24px;
                }
            }
        }
    }
}